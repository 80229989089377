import Image from "gatsby-image"
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Transition } from "@headlessui/react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Helmet } from "react-helmet"
import downArrow from "../images/downArrow.png"
import upArrow from "../images/upArrow.png"
import crossImg from "../images/cross.png"
import lineImg from "../images/line.png"

const Header = props => {
  const [word, setWord] = useState("")
  // const [count, setCount] = useState(0);
  // const [stepCount, setStepCount] = useState(0);
  const [visible, setVisible] = useState("")
  const [isMobileOpen, setIsMobileOpen] = useState(false)
  const [flyoutMenuOpen, setFlyoutMenuOpen] = useState(null)

  const search = {
    children: [
      { children: [], title: "Search Page", target: "#", _key: "thrdgfcgher" },
      { children: [], title: "Search Site", target: "#", _key: "thrdgfcghhf" },
    ],
    title: "SEARCH",
    target: "#",
    _key: "thrdgfcgh",
  }

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logos/Auzu_Logo_White.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sanitySettings {
        navigation {
          _key
          title
          target
          children {
            _key
            title
            target
            children {
              _key
              _type
              title
              target
            }
          }
        }
      }
    }
  `)

  const navMenu = data?.sanitySettings?.navigation

  function keyDown(e) {
    if (e.key === "Enter" && visible === "page") {
      window.find(word)
    } else if (e.key === "Enter" && visible === "site") {
      navigate(`/search?query=${word}`)
    }
  }

  return (
    <header>
      <meta charSet="UTF-8" />
      <title data-react-helmet="true">
        The Legends of Auzu | A free-to-play tabletop roleplaying game
      </title>

      <meta
        name="description"
        content="The Legends of Auzu is a free-to-play tabletop roleplaying game and a fantastical world to explore. Everything you need to play can be found right here - check out the Getting Started page to begin your next journey!"
      />
      <meta
        name="keywords"
        content="legends, auzu, tabletop, roleplaying, role, playing, game, free, d&d"
      />
      <meta name="author" content="@legendsofauzu" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      ></meta>

      <div className="z-10 relative bg-white shadow">
        <div className="mx-auto px-4 sm:px-6 headerClass">
          <div className="flex justify-between items-center py-1 px-3 mainHeaderStyles">
            <div className="">
              <Link to="/" class="flex">
                <Image
                  fixed={data.logo.childImageSharp.fixed}
                  alt="Legends of Auzu Logo"
                  className="image-logo"
                />
              </Link>
            </div>
            {navMenu?.map(navlink =>
              navlink.children.length === 0 ? (
                navlink?.title !== "SUPPORT US" && (
                  <div className="hidden md:flex mx-2">
                    <Link
                      to={navlink.target}
                      className="text-base leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 linkStyles"
                    >
                      {navlink.title}
                    </Link>
                  </div>
                )
              ) : (
                <div className="hidden md:flex mx-2">
                  <DropdownLink
                    navlink={navlink}
                    handleMobile={() => {
                      setIsMobileOpen(false)
                    }}
                  />
                </div>
              )
            )}
            <div className="mx-2">
              <DropdownLink
                navlink={search}
                alterSearch={type => setVisible(type)}
                handleMobile={() => {
                  setIsMobileOpen(false)
                }}
              />
            </div>
            <div className="hidden md:flex mx-2">
              {navMenu?.map(
                navlink =>
                  navlink?.title === "SUPPORT US" && (
                    <div className="hidden md:flex mx-2">
                      <Link
                        to={navlink.target}
                        className="text-base leading-6 font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 linkStyles"
                      >
                        {navlink.title}
                      </Link>
                    </div>
                  )
              )}
            </div>
            <div className="-mr-2 -my-2 md:hidden">
              <button
                type="button"
                onClick={() => setIsMobileOpen(true)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        {/* <!-- Mobile menu --> */}
        <Transition
          show={isMobileOpen}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50 bgc">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div
                  className="flex justify-between"
                  // style={{ height: "100vh" }}
                >
                  <div className="z-10">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6">
                      <div className="flex justify-between py-1 ">
                        <nav className="md:flex space-x-8 space-y-5">
                          {navMenu?.map(navlink =>
                            navlink.children.length === 0 ? (
                              <Link
                                to={navlink.target}
                                className="text-base font-semibold text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 mobileStyles wc"
                              >
                                {navlink.title}
                              </Link>
                            ) : (
                              <DropdownLink
                                navlink={navlink}
                                mobileOpen={isMobileOpen}
                                handleMobile={() => {
                                  setIsMobileOpen(false)
                                }}
                              />
                            )
                          )}
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      onClick={() => setIsMobileOpen(false)}
                      className="inline-flex justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    >
                      {/* <!-- Heroicon name: x --> */}
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
        <div style={{ float: "right" }} className="mr-4 mb-1">
          {visible === "page" && (
            <div
              className="flex items-center py-2 bg-white "
              style={{ borderRadius: "5px" }}
            >
              <input
                className="h-6 pl-1"
                onChange={e => setWord(e.target.value)}
                style={{ outline: "none" }}
                onKeyDown={e => keyDown(e)}
              />
              <img src={lineImg} alt="line"></img>
              <button
                className="w-full flex items-center px-2"
                style={{ outline: "none" }}
                onClick={() => window.find(word, false, true)}
              >
                <span className="text-center text-md font-display tracking-wide">
                  <img src={upArrow} alt="up-arrow"></img>
                </span>
              </button>
              <button
                className="w-full flex items-center px-2"
                style={{ outline: "none" }}
                onClick={() => window.find(word, false, false)}
              >
                <span className="text-center text-md font-display tracking-wide">
                  <img src={downArrow} alt="down-arrow"></img>
                </span>
              </button>
              <button
                className="w-full flex items-center px-2"
                style={{ outline: "none" }}
                onClick={() => setVisible("")}
              >
                <span className="text-center text-md font-display tracking-wide">
                  <img
                    style={{ height: "17px" }}
                    src={crossImg}
                    alt="cross-arrow"
                  ></img>
                </span>
              </button>
            </div>
          )}
          {visible === "site" && (
            <div
              className="flex items-center py-2 bg-white "
              style={{ borderRadius: "5px" }}
            >
              <input
                className="h-6 px-2"
                onChange={e => setWord(e.target.value)}
                style={{ outline: "none", width: "250px" }}
                onKeyDown={e => keyDown(e)}
              />
            </div>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header

const DropdownLink = ({ navlink, mobileOpen, alterSearch, handleMobile }) => {
  const [expanded, setExpanded] = React.useState(false)
  const buttonClass = mobileOpen
    ? "text-gray-500 inline-flex items-center space-x-2 text-base font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150 wc"
    : "text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"

  // const buttonClass = mobileOpen ? "black" : "black"
  // console.log(navlink, 'navlink')

  var longest = navlink?.children.reduce(function (a, b) {
    return a.title.length > b.title.length ? a : b
  })
  const maxLength = longest.title.length < 10 ? 11 : longest.title.length

  async function handleShowPage(child) {
    setExpanded(false)
    if (child.title === "Search Page") {
      alterSearch("page")
    } else {
      alterSearch("site")
    }
  }

  return (
    <div
      className={mobileOpen ? "relative dropdownClass" : "relative"}
      onFocus={() => setExpanded(true)}
      onMouseEnter={() => setExpanded(true)}
      onBlur={() => setExpanded(false)}
      onMouseLeave={() => setExpanded(false)}
      onClick={() => setExpanded(true)}
    >
      {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
      <button type="button" className={buttonClass}>
        {navlink.title}
      </button>

      <Transition
        show={expanded}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="z-20 origin-top-left absolute left-0 mt-2 width-name bg-white rounded-md shadow-lg  ring-1 ring-black ring-opacity-5 bgc"
      >
        <div
          className="py-4"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {navlink?.title === "SEARCH"
            ? navlink?.children?.map(child =>
                child.children?.length === 0 ? (
                  <span
                    to="#"
                    className="block px-6 py-1 text-base width-name font-semibold text-gray-900 hover:text-blue-500 transform hover:translate-x-1 duration-300 ease-in-out wc"
                    role="menuitem"
                    style={{ overflowWrap: "break-word" }}
                    onClick={() => handleShowPage(child)}
                  >
                    {child.title}
                  </span>
                ) : (
                  <>
                    <SubDropdownLink
                      navlink={child}
                      onHandleExpand={() => handleMobile()}
                      mobileOpen={mobileOpen}
                    />
                  </>
                )
              )
            : navlink?.children?.map(child =>
                child.children?.length === 0 ? (
                  <Link
                    to={child?.target}
                    // className="block px-6 py-2 text-base font-semibold text-gray-400 hover:text-gray-800 transform hover:translate-x-1 duration-300 ease-in-out"
                    className="block px-6 py-1 text-base width-name font-semibold text-gray-900 hover:text-blue-500 transform hover:translate-x-1 duration-300 ease-in-out wc"
                    role="menuitem"
                    style={{ overflowWrap: "break-word" }}
                  >
                    {child.title}
                  </Link>
                ) : (
                  <>
                    <SubDropdownLink
                      navlink={child}
                      onHandleExpand={() => handleMobile()}
                      mobileOpen={mobileOpen}
                      maxLength={maxLength || "15"}
                    />
                  </>
                )
              )}
        </div>
      </Transition>
    </div>
  )
}

const SubDropdownLink = ({
  navlink,
  mobileOpen,
  onHandleExpand,
  maxLength,
}) => {
  const [expanded, setExpanded] = React.useState(false)
  // const buttonClass = mobileOpen
  //   ? "text-gray-500 inline-flex items-center space-x-2 text-base font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
  //   : "text-gray-500 inline-flex items-center space-x-2 text-base leading-6 font-medium hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150"
  // console.log(mobileOpen, 'mobile open')
  const buttonClass = mobileOpen
    ? "black"
    : "block px-6 py-2 text-base font-semibold text-gray-900 hover:text-blue-500 transform hover:translate-x-1 duration-300 ease-in-out"
  const leftValue = maxLength * 10 + "px"

  // console.log("navLink==========", navlink)
  return (
    <div
      className={mobileOpen ? "relative dropdownClass" : "relative"}
      onFocus={() => setExpanded(true)}
      onMouseEnter={() => setExpanded(true)}
      onBlur={() => setExpanded(false)}
      onMouseLeave={() => setExpanded(false)}
      onDoubleClick={() => navigate(navlink?.target)}
    >
      {/* <!-- Item active: "text-gray-900", Item inactive: "text-gray-500" --> */}
      {/* <button
        type="button"
        className={buttonClass}
        onClick={async () => {
          navigate(`${navlink?.target}/`)
        }}
      >
        {navlink.title}
      </button> */}

      {mobileOpen ? (
        <Link
          // to={navlink?.target}
          // className="block px-6 py-2 text-base font-semibold text-gray-400 hover:text-gray-800 transform hover:translate-x-1 duration-300 ease-in-out"
          className="block px-6 py-2 text-base font-semibold text-gray-900 hover:text-blue-500 transform hover:translate-x-1 duration-300 ease-in-out wc"
          role="menuitem"
        >
          {navlink.title}
        </Link>
      ) : (
        <Link
          to={navlink?.target}
          // className="block px-6 py-2 text-base font-semibold text-gray-400 hover:text-gray-800 transform hover:translate-x-1 duration-300 ease-in-out"
          className="block px-6 py-2 text-base font-semibold text-gray-900 hover:text-blue-500 transform hover:translate-x-1 duration-300 ease-in-out wc"
          role="menuitem"
        >
          {navlink.title}
        </Link>
      )}

      <Transition
        show={expanded}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="z-20 origin-top-left absolute mt-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 childItems bgc"
        style={{ left: leftValue }}
      >
        <div
          className="py-4"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {navlink?.children?.map(child => (
            <div
              onClick={() => {
                if (mobileOpen) {
                  onHandleExpand()
                }
              }}
            >
              <AnchorLink
                to={child.target}
                title={child.title}
                className="block px-6 py-2 text-base width-name font-semibold text-gray-900 hover:text-blue-500 transform hover:translate-x-1 duration-300 ease-in-out wc"
              />
            </div>
          ))}
        </div>
      </Transition>
    </div>
  )
}
