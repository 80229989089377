/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

import Header from "./header"
import "../styles/global.css"
import "../styles/tailwind.css"

const Layout = ({ children }) => {
  const location = useLocation();
  const [top, setTop] = useState(false)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (location?.pathname === '/') {
      setTop(false)
    } else {
      setTop(true)
    }
  }, [])

  return (
    <div
      className="overflow-hidden">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div>
        <main style={{ marginTop: top ? '95px' : '0px' }}>{children}</main>
      </div>
    </div >
  )
}

export default Layout
